<template>
  <v-container fluid class="py-0">
    <v-container class="py-0">
      <v-row>
        <v-col md="2">
          <v-text-field
            v-model.trim="filter.search"
            v-on:click:clear="clearFilter('search')"
            clearable
            dense
            filled
            flat
            label="Search"
            hide-details
            solo
            :disabled="pageLoading"
            color="cyan"
            @keydown.enter="getActivityLog"
            @keydown.tab="getActivityLog"
          ></v-text-field>
        </v-col>
        <v-col md="3">
          <v-menu
            v-model="datePicker"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                filled
                flat
                label="Date Range"
                hide-details
                solo
                :disabled="pageLoading"
                v-on:click:clear="clearFilter('dates')"
                clearable
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                :value="formattedDate"
                color="cyan"
              ></v-text-field>
            </template>
            <v-date-picker
              color="cyan"
              range
              :disabled="pageLoading"
              v-on:change="getActivityLog"
              v-model="dates"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col md="2">
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                tile
                :disabled="pageLoading"
                v-on:click="getActivityLog"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
            <span>Search</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-container>
    <v-simple-table fixed-header height="400px">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="simple-table-th">Description</th>
            <th width="200" class="simple-table-th">IP Address</th>
            <th width="300" class="simple-table-th">Created Time</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="activityLog.length > 0">
            <tr v-for="(history, index) in activityLog" :key="index">
              <td class="simple-table-td">
                {{ history.description }}
                <span class="text--secondary">
                  <template v-if="history.added_by">
                    by {{ history.added_by.display_name }}</template
                  >
                </span>
              </td>
              <td class="simple-table-td" width="200">
                {{ history.ip_address }}
              </td>
              <td class="simple-table-td" width="300">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      v-if="history.created_at"
                      class="mx-2 my-1 custom-grey-border"
                      color="blue-grey darken-1"
                      text-color="white"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-avatar left>
                        <v-icon>mdi-clock-outline</v-icon>
                      </v-avatar>
                      <p class="m-0 custom-nowrap-ellipsis">
                        {{ history.created_at }}
                      </p>
                    </v-chip>
                  </template>
                  <p class="m-0 custom-nowrap-ellipsis">
                    {{ formatDateTime(history.added_at) }}
                  </p>
                </v-tooltip>
              </td>
            </tr>
          </template>
          <template v-else>
            <template v-if="pageLoading">
              <tr v-for="row in 5" :key="row">
                <td>
                  <v-skeleton-loader
                    type="text"
                    class="custom-skeleton table-rows-text"
                  ></v-skeleton-loader>
                </td>
                <td>
                  <v-skeleton-loader
                    type="text"
                    class="custom-skeleton table-rows-text"
                  ></v-skeleton-loader>
                </td>
                <td>
                  <v-skeleton-loader
                    type="text"
                    class="custom-skeleton table-rows-text"
                  ></v-skeleton-loader>
                </td>
              </tr>
            </template>
            <tr v-else-if="!pageLoading">
              <td colspan="3">
                <p
                  class="m-0 row-not-found text-center font-weight-500 font-size-16"
                >
                  <img
                    :src="$assetURL('media/error/empty.png')"
                    class="row-not-found-image"
                  />
                  Uhh... There are no activity log at the moment.
                </p>
              </td>
            </tr>
          </template>
        </tbody>
      </template>
    </v-simple-table>
    <ListingFooter
      :dataLoading="pageLoading"
      :currentPage="current_page"
      :totalPages="total_pages"
      :showingFrom="showingFrom"
      :showingTo="showingTo"
      :totalRows="total_rows"
    ></ListingFooter>
  </v-container>
</template>

<script>
import { QUERY } from "@/core/services/store/request.module";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingFooter from "@/view/pages/partials/Listing-Footer";
import { PaginationEventBus } from "@/core/lib/pagination.lib";

export default {
  mixins: [CommonMixin],
  data() {
    return {
      dates: [],
      pageLoading: true,
      activityLog: new Array(),
      filter: {
        search: null,
      },
      per_page: 10,
      current_page: 1,
      offset: 0,
      total_pages: 0,
      total_rows: 0,
    };
  },
  components: {
    ListingFooter,
  },
  props: {
    type: {
      required: true,
      type: String,
      default: "",
    },
  },
  methods: {
    clearFilter(field) {
      const _this = this;
      switch (field) {
        case "search":
          _this.filter.search = null;
          break;
        case "dates":
          _this.dates = [];
          break;
      }
      _this.getActivityLog();
    },
    getActivityLog() {
      const _this = this;
      _this.pageLoading = true;
      _this.activityLog = [];
      _this.$store
        .dispatch(QUERY, {
          url: "activity-log/" + _this.type,
          data: {
            per_page: _this.per_page,
            current_page: _this.current_page,
            search: _this.filter.search,
            start_date: _this.dates[0] || null,
            end_date: _this.dates[1] || null,
          },
        })
        .then(({ data }) => {
          _this.offset = data.offset;
          _this.activityLog = data.rows;
          _this.total_pages = Math.ceil(data.total_rows / data.per_page);
          _this.current_page = data.current_page;
          _this.total_rows = data.total_rows;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  beforeDestroy() {
    /*PaginationEventBus.$off("update:pagination");*/
  },
  mounted() {
    const _this = this;
    _this.getActivityLog();

    PaginationEventBus.$on("update:pagination", (param) => {
      _this.current_page = param;
      _this.getActivityLog();
    });
  },
  computed: {
    showingFrom() {
      return this.lodash.isEmpty(this.activityLog) === false
        ? this.offset + 1
        : 0;
    },
    showingTo() {
      return this.offset + (this.activityLog ? this.activityLog.length : 0);
    },
  },
};
</script>
